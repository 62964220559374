<template>
  <v-dialog v-model="show" persistent width="500">
    <v-card>
      <v-toolbar dense dark color="primary" tile flat>
        <v-toolbar-title>
          <span>Detalle de intentos</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('cancelar')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-3">
        <v-subheader class="ma-0 pa-0">Alumno</v-subheader>
        <v-card outlined>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar color="primary" size="30">
                <v-img
                  v-if="calificacion.avatar"
                  :src="`${imageURL}/avatar/${calificacion.avatar}`"
                  alt="UserImage"
                ></v-img>
                <v-icon v-else color="white">mdi-account</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{
                calificacion.nombreCompleto
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip label>
                <span class="text-button">{{
                  calificacion.calificaciones[0].calificacion
                }}</span>
              </v-chip>
            </v-list-item-action>
          </v-list-item>
        </v-card>
        <v-subheader class="ma-0 pa-0">Intentos</v-subheader>
        <v-list>
          <v-list-item
            v-for="resultado in calificacion.resultados"
            :key="resultado._id"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-text-box-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="resultado.terminado"
                >{{ resultado.calificacion.toFixed(2) }} Puntos
                obtenidos</v-list-item-title
              >
              <v-list-item-title v-else>Resultado en proceso</v-list-item-title>
              <v-list-item-subtitle v-if="resultado.terminado"
                >Entregado:
                {{
                  getFechaLocale(resultado.fechaTerminado)
                }}</v-list-item-subtitle
              >
              <v-list-item-subtitle v-else
                >Entrega pendiente</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action v-if="!vistaSeguimiento">
              <v-btn color="primary" small @click="verResultado(resultado._id)">
                <span>Ver intento</span>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

export default {
  props: {
    show: { type: Boolean, default: false },
    calificacion: { type: Object, required: true },
  },

  computed: {
    ...mapGetters(["imageURL", "vistaSeguimiento"]),
  },

  methods: {
    getFechaLocale(isoDate) {
      return DateTime.fromISO(isoDate)
        .setLocale("es")
        .setZone("system")
        .toLocaleString(DateTime.DATETIME_SHORT);
    },

    verResultado(idResultado) {
      this.$router.push({
        name: "PresentarExamen",
        params: { idResultado },
      });
    },
  },
};
</script>
